<template>
  <el-card>
    <CommonForm
      :submit-form="submitFormFunc"
      :rules="{}"
      :main-form.sync="mainForm"
      :label-id="id"
    />
  </el-card>
</template>

<script>
import CommonForm from '@/components/commonForm/index'
import {
  createRestLabel,
  getRestLabelInfoPreview,
  updateRestLabelInfo
} from '@/services/rests'
import loading from '@/utils/loading.json'
export default {
  components: {
    CommonForm
  },
  data () {
    return {
      submitFormFunc: createRestLabel,
      mainForm: {
        name: null,
        name_en: null,
        rank: null
      },
      id: ''
    }
  },
  created () {
    this.id = this.$route.query.id || ''
    this.initData()
  },
  methods: {
    async initData() {
      if (this.id) {
        this.$store.state.app.loading = this.$loading(loading)
        this.submitFormFunc = updateRestLabelInfo
        const res = await getRestLabelInfoPreview(this.id)
        if (res.status === 200 && res.data) {
          this.mainForm = {...(res.data.rest_tag)}
        }
        this.$store.state.app.loading.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>